<template>
  <vue-scroll>
    <v-card>
      <v-toolbar color="#4e7ec1" dark class="mb-6">
        <v-toolbar-title>
          PARAMETRAGE
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeParamsModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <div class="d-flex flex-row  justify-space-between">
          <div class="d-flex mx-5 flex-column align-stretch justify-space-between" style="width: 100%"> 
            <div class="d-flex flex-row align-start justify-space-between">
              <v-card-text style="width: 48%">
                <v-card class="mb-4">
                  <v-card-title>
                    <!-- v-select avec tout les mail  -->
                    <v-autocomplete 
                      :items="usersGtp" 
                      :item-text="item => `${item.firstname} ${item.lastname}`"
                      label="Adresse mail direction" 
                      item-value="emailAddress" 
                      multiple
                      v-model="parametrage.directionMail"
                      >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.firstname + ' ' + data.item.lastname }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.firstname + ' ' + data.item.lastname }}
                      </template>
                    </v-autocomplete>
                  </v-card-title>
                </v-card>
                <v-card>
                  <v-card-title>
                    <v-autocomplete :items="usersGtp" :item-text="item => `${item.firstname} ${item.lastname}`"
                      label="Adresse mail SysAdmin" item-value="emailAddress" multiple
                      v-model="parametrage.sysAdminMail">
                      <template slot="selection" slot-scope="data">
                        {{ data.item.firstname + ' ' + data.item.lastname }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.firstname + ' ' + data.item.lastname }}
                      </template>
                    </v-autocomplete>
                  </v-card-title>
                </v-card>
              </v-card-text>
              <v-card-text style="width: 48%">
                <v-card class="mb-4">
                  <v-card-title>
                    <v-autocomplete 
                      :items="usersGtp" 
                      :item-text="item => `${item.firstname} ${item.lastname}`"
                      label="Adresse mail callCenter" 
                      item-value="emailAddress" 
                      multiple
                      v-model="parametrage.callCenterMail"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.firstname + ' ' + data.item.lastname }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.firstname + ' ' + data.item.lastname }}
                      </template>
                    </v-autocomplete>
                  </v-card-title>
                </v-card>
                <v-card>
                  <v-card-title>
                    <v-autocomplete 
                      :items="usersGtp" 
                      :item-text="item => `${item.firstname} ${item.lastname}`"
                      label="Adresse mail équipe technique" 
                      item-value="emailAddress" 
                      multiple
                      v-model="parametrage.equipeTechniqueMail"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.firstname + ' ' + data.item.lastname }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.firstname + ' ' + data.item.lastname }}
                      </template>
                    </v-autocomplete>
                  </v-card-title>
                </v-card>
              </v-card-text>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="closeParamsModal">
            Annuler
          </v-btn>
          <v-btn color="success" variant="text" @click="closeSaveParams">
            Créer
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
 
  </vue-scroll>
</template>
<script>
import BasesService from '@/Services/SupportVision/BasesService';
import { mapMutations, mapState } from 'vuex';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'basesParametrage',
  data: () => ({
    dialogParams: false,
    parametrage: {
      directionMail: [],
      sysAdminMail: [],
      callCenterMail: [],
      equipeTechniqueMail: [],
    },
    editModal: false,
  }),
  computed: {
    ...mapState(["usersGtp", "basesParams", "informations"]),
  },
  methods: {
    ...mapMutations(["setBasesParams", "setRights"]),
    closeParamsModal() {
      this.dialogParams = false;
      this.clearparametrage();
      this.$emit('close-modal');
    },
    async closeSaveParams() {
      const res = await BasesService.createParams(this.parametrage);
      this.$store.commit('setBasesParams', [res.data]);
      const user = this.informations;
      this.$store.commit('setRights', {"params": res.data.params,"user":user});
      this.clearparametrage();
      this.closeParamsModal();
    },  
    clearparametrage() {
      this.parametrage = {
        directionMail: [],
        sysAdminMail: [],
        callCenterMail: [],
        equipeTechniqueMail: [],
      };
    },  
  },
  async mounted() {
  },
};
</script>
