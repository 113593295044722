<template>
  <vue-scroll>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          EDIT PARAMETRAGE
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-title>
          Édition des paramètres
        </v-card-title> 
        <v-card-text> 
          <!-- v-select avec tout les mail  -->
          <v-autocomplete 
            :items="usersGtp" 
            :item-text="item => `${item.firstname} ${item.lastname}`"
            label="Adresse mail direction" 
            item-value="emailAddress" 
            multiple
            v-model="parametrage.directionMail"
            >
            <template slot="selection" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
          </v-autocomplete>

          <v-autocomplete :items="usersGtp" :item-text="item => `${item.firstname} ${item.lastname}`"
            label="Adresse mail SysAdmin" item-value="emailAddress" multiple
            v-model="parametrage.sysAdminMail">
            <template slot="selection" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
          </v-autocomplete>

          <v-autocomplete 
            :items="usersGtp" 
            :item-text="item => `${item.firstname} ${item.lastname}`"
            label="Adresse mail callCenter" 
            item-value="emailAddress" 
            multiple
            v-model="parametrage.callCenterMail"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
          </v-autocomplete>

          <v-autocomplete 
            :items="usersGtp" 
            :item-text="item => `${item.firstname} ${item.lastname}`"
            label="Adresse mail équipe technique" 
            item-value="emailAddress" 
            multiple
            v-model="parametrage.equipeTechniqueMail"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
          </v-autocomplete>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" @click="closeModal">
            Annuler
          </v-btn>
          <v-btn color="warning" @click="saveModal">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </vue-scroll>
</template>
<script>
import BasesService from '@/Services/SupportVision/BasesService';
import { mapMutations, mapState } from 'vuex';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'editParametrage',
  data: () => ({
    editParams: false,
  }),
  computed: {
    ...mapState(["usersGtp", "basesParams", "paramBaseId", "informations"]),
    // trouver le parametrage par id
    parametrage() {
      const param = this.basesParams.find((param) => param._id === this.paramBaseId);
      return param ? param.params : {};
    },
  },
  methods: {
    ...mapMutations(["setBasesParams", "setRights"]),
    closeModal() {
      this.$emit('closeEditModal');
    },
    async saveModal() {
      const res = await BasesService.editParams(this.paramBaseId, this.parametrage);
      if (res) {
        this.$emit('closeEditModal');
        const user = this.informations;
        this.$store.commit('setRights', {"params": res.data.params,"user":user});
      }
    },
  }, 
};
</script>
