<template>
  <vue-scroll>
    <v-card>
      <v-toolbar color="primary" dark class="">
        <v-toolbar-title>
          LISTES PARAMETRAGE
        </v-toolbar-title>
        <v-spacer></v-spacer> 
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="dataGet"
        :items-per-page="5"
        class="elevation-1"
        :footer-props="{
          'items-per-page-text': $t('doubleAuth.rows-per-page'),
          'items-per-page-all-text': $t('doubleAuth.all'),
        }"
      >
      <template v-slot:item="{ item }">
        <tr>
          <td v-if="item.params.directionMail !== undefined && item.params.directionMail.length > 0">
            <v-menu offset-y max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-2" v-on="on" v-bind="attrs">
                  {{ item.params.directionMail[0] }}
                  <v-icon>mdi-menu-down</v-icon>
                </div>
              </template>

              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(mail, index) in item.params.directionMail" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ mail }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </td>
          <td v-else>-</td>
          <td v-if="item.params.sysAdminMail !== undefined && item.params.sysAdminMail.length > 0">
            <v-menu offset-y max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-2" v-on="on" v-bind="attrs">
                  {{ item.params.sysAdminMail[0] }}
                  <v-icon>mdi-menu-down</v-icon>
                </div>
              </template>

              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(mail, index) in item.params.sysAdminMail" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ mail }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </td>
          <td v-else>-</td>
          <td v-if="item.params.callCenterMail !== undefined && item.params.callCenterMail.length > 0">
            <v-menu offset-y max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-2" v-on="on" v-bind="attrs">
                  {{ item.params.callCenterMail[0] }}
                  <v-icon>mdi-menu-down</v-icon>
                </div>
              </template>

              <v-list>
                <v-list-item
                v-for="(mail, index) in item.params.callCenterMail"
                :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ mail }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td v-else>-</td>
          <td v-if="item.params.equipeTechniqueMail !== undefined && item.params.equipeTechniqueMail.length > 0">
            <v-menu offset-y max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-2" v-on="on" v-bind="attrs">
                  {{ item.params.equipeTechniqueMail[0] }}
                  <v-icon>mdi-menu-down</v-icon>
                </div>
              </template>

              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(mail, index) in item.params.equipeTechniqueMail" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ mail }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </td>
          <td v-else>-</td>
          <td class="d-flex align-center justify-center">
            <v-btn icon @click="editParams(item._id)">
              <v-icon color="warning">mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn icon @click="deleteParamsModal(item._id)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      </v-data-table>
    </v-card>
    <!-- dialog edittion -->
    <v-dialog v-model="editParamsModal" max-width="30%">
      <editParametrage @closeEditModal="closeEditModal" />
    </v-dialog>

    <!-- dialog suppression -->
    <v-dialog v-model="alertDelete" max-width="25%">
      <v-card>
        <v-toolbar color="danger" dense>
          <v-toolbar-title>Confirmation de suppression</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <span>Êtes-vous sûr(e) de vouloir supprimer cette configuration ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn text @click="closeModal" >Annuler</v-btn>
            <v-btn color="error" @click="deleteParams" >Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </vue-scroll>
</template>
<script>

import BasesService from '@/Services/SupportVision/BasesService';
import { mapMutations, mapState } from 'vuex';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import editParametrage from './editParametrage.vue';
import CodeSnippet from '@/Components/Commons/UiElements/CodeSnippet.vue';

export default {
  name: 'listesParametrage',
  components: {
    editParametrage,
    CodeSnippet,
  },
  data: () => ({
    listParams: null,
    editParamsModal: false,
    alertDelete: false,
    dataGet: [],
    headers: [
      { text: 'Direction Mail', value: 'directionMail',  },
      { text: 'SysAdmin Mail', value: 'sysAdminMail',  },
      { text: 'CallCenter Mail', value: 'callCenterMail',  },
      { text: 'Equipe Technique Mail', value: 'equipeTechniqueMail',  },
      { text: 'Action', value: 'action', sortable: false,  },
    ],
  }),
  watch: {
    basesParams: {
      handler: function (val) {
        this.dataGet = val;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["usersGtp", "basesParams", "paramBaseId", "informations"]),  
  },
  methods: {
    ...mapMutations(["setBasesParams", "setParamBaseId", "setRights", "setConfigParams"]),
    async editParams(id) {
      this.editParamsModal = true;
      this.setParamBaseId(id);
    },
    closeEditModal() {
      this.editParamsModal = false;
    },
    formattedText(item) {
      return item.filter(value => value.toLowerCase() !== "").join(", ");
    }, 
    async deleteParamsModal(id) {
      this.alertDelete = true;
      this.setParamBaseId(id);
    },
    async deleteParams() {
      await BasesService.removeParams(this.paramBaseId);
      this.dataGet = await BasesService.getParams();
      // mettre a jour la liste des parametres
      // this.dataGet.splice(this.dataGet.findIndex((param) => param._id === this.paramBaseId), 1);
      // this.setBasesParams(this.basesParams.filter((param) => param._id !== this.paramBaseId));
      // const res = await BasesService.getParams()
      this.setBasesParams(this.dataGet);  
      const user = this.informations;
      await this.$store.commit('setRights', {"params": this.dataGet,"user":user});

      this.closeModal();
    },
    closeModal() {
      this.alertDelete = false;
      this.$emit('close-modal');
    },
  },
  async mounted() {
    const res = await BasesService.getParams();
    this.dataGet = res;
  },
};
</script>
<style scoped>
.v-tab {
  justify-content: flex-start;
}
</style>
